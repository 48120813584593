import React, { useEffect, useState } from "react";
import Header from "../../../components/header";
import Layout from "../../../components/layout";
import WithProviders from "../../../components/WithProviders";
import { Message } from "../../../lib/getMessage";

import devTeamOne from "../../images/srvc-frmwrk-lib-team-one.png";
import devTeamOne2 from "../../images/srvc-frmwrk-lib-team-one@2x.png";

import devTeamTwo from "../../images/srvc-frmwrk-lib-team-two.png";
import devTeamTwo2 from "../../images/srvc-frmwrk-lib-team-two@2x.png";

import devTeamThree from "../../images/srvc-frmwrk-lib-team-three@2x.png";
import devTeamThree2 from "../../images/srvc-frmwrk-lib-team-three@2x.png";

import "./style.css";
import HeroBanner from "../../../components/HeroBanner";

import ServiceDevDesc from "../../images/srvc-dev-desc.png";
import ServiceDevDesc2 from "../../images/srvc-dev-desc@2x.png";
import ServiceDevDesc3 from "../../images/srvc-dev-desc@3x.png";
import ProductWithImage from "../../../components/ProductWithImage";

import SrvcFrmwrkLibBottom from "../../images/srvc-frmwrk-lib-bottom.png";
import SrvcFrmwrkLibBottom2 from "../../images/srvc-frmwrk-lib-bottom@2x.png";
import SrvcFrmwrkLibBottom3 from "../../images/srvc-frmwrk-lib-bottom@3x.png";

import SrvcFrmwrkLibBottomMobile from "../../images/srvc-frmwrk-lib-bottom-mobile.png";
import SrvcFrmwrkLibBottomMobile2 from "../../images/srvc-frmwrk-lib-bottom-mobile@2x.png";
import SrvcFrmwrkLibBottomMobile3 from "../../images/srvc-frmwrk-lib-bottom-mobile@3x.png";

const Step = (props) => (
  <div className={`step ${props.name}`}>
    <div className="step-media">
      <img
        src={props.image.normal}
        srcSet={`${props.image.xx} 2x`}
        alt="media"
        loading="lazy"
      />
      <div className="content">{props.desc}</div>
    </div>
  </div>
);

const DevelopmentCycles = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window?.innerWidth < 480) {
      setIsMobile(true);
    }
  }, []);
  const { language, updateLanguage, direction, location } = props;
  const isDirectionRTL = direction === "rtl";
  return (
    <Layout
      direction={direction}
      showFooterForm
      language={language}
      location={location}
    >
      <div>
        <Header
          current="service"
          language={language}
          updateLanguage={updateLanguage}
          direction={direction}
        />
        <div className="srvc-dev-cycl">
          <HeroBanner
            title={
              <Message dictKey="services.acceleratingdevelopmentcycles.title" />
            }
            className="dev-cycl-banner"
          />
          <section className="srvc-dev-desc">
            <ProductWithImage
              name="dev-desc"
              className="dev-desc"
              description={[
                {
                  type: "p",
                  val: (
                    <Message dictKey="services.acceleratingdevelopmentcycles.devdesc.description1" />
                  ),
                  id: 1,
                },
                {
                  type: "p",
                  val: (
                    <Message dictKey="services.acceleratingdevelopmentcycles.devdesc.description2" />
                  ),
                  id: 2,
                },
                {
                  type: "p",
                  val: (
                    <Message dictKey="services.acceleratingdevelopmentcycles.devdesc.description3" />
                  ),
                  id: 2,
                },
              ]}
              image={{
                normal: ServiceDevDesc,
                xx: ServiceDevDesc2,
                xxx: ServiceDevDesc3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
          </section>
          <section className="srvc-frmwrk-lib">
            <div className="steps upper">
              <Step
                name="step-one"
                desc={
                  <Message dictKey="services.acceleratingdevelopmentcycles.steps.step1" />
                }
                image={{
                  normal: devTeamOne,
                  xx: devTeamOne2,
                }}
              />
              <Step
                name="step-two"
                desc={
                  <Message dictKey="services.acceleratingdevelopmentcycles.steps.step2" />
                }
                image={{
                  normal: devTeamTwo,
                  xx: devTeamTwo2,
                }}
              />
              <Step
                name="step-three"
                desc={
                  <Message dictKey="services.acceleratingdevelopmentcycles.steps.step3" />
                }
                image={{
                  normal: devTeamThree,
                  xx: devTeamThree2,
                }}
              />
            </div>
            <div className="bottom">
              <div className="media">
                {isMobile ? (
                  <img
                    src={SrvcFrmwrkLibBottomMobile}
                    srcSet={`${SrvcFrmwrkLibBottomMobile2} 2x, ${SrvcFrmwrkLibBottomMobile3} 3x`}
                    alt="media"
                    loading="lazy"
                  />
                ) : (
                  <img
                    src={SrvcFrmwrkLibBottom}
                    srcSet={`${SrvcFrmwrkLibBottom2} 2x, ${SrvcFrmwrkLibBottom3} 3x`}
                    alt="media"
                    loading="lazy"
                  />
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default WithProviders(DevelopmentCycles);
