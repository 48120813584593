import React from "react";
import "./style.css";
const Image = ({ image }) => {
  const twoX = image.xx ? `${image.xx} 2x` : "";
  const threeX = image.xxx ? `${image.xxx} 3x` : "";
  return (
    <img
      src={image.normal}
      srcSet={`${twoX}, ${threeX}`}
      className="img"
      alt="media"
      loading="lazy"
    />
  );
};
const ProductWithImage = (props) => {
  const {
    heading,
    description,
    image,
    className,
    name,
    imgList,
    isDirectionRTL,
  } = props;

  const getDescription = (item) => {
    const { type, val } = item;
    switch (type) {
      case "span":
        return <span>{val}</span>;
      case "component":
        return val;
      default:
        return <p>{val}</p>;
    }
  };
  return (
    <div className={`pr-with-img ${className}`} name={`${name}`}>
      <div className="product wrap">
        <div className={`product-details ${isDirectionRTL ? "rtl" : ""}`}>
          {heading ? <h1>{heading}</h1> : null}
          {description?.map((item) => (
            <React.Fragment key={item.id}>
              {getDescription(item)}
            </React.Fragment>
          ))}
        </div>
        <div className="product-media">
          {imgList?.length ? (
            imgList.map((image) => <Image image={image} />)
          ) : (
            <Image image={image} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductWithImage;
