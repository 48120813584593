import React from "react";
import "./style.css";
const HeroBanner = (props) => {
  const { title, description, bgImg, className, isDirectionRTL } = props;
  return (
    <section
      className={`hero-banner ${className || ""}`}
      style={{ backgroundImage: bgImg }}
    >
      <div className={`wrap ${isDirectionRTL && "wrap-rtl"}`}>
        <h1 className="">{title || ""}</h1>
        <p className="">{description || ""}</p>
      </div>
    </section>
  );
};

export default HeroBanner;
